import { Component, Vue } from 'vue-property-decorator'
import authModule from '@/store/auth'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import http from '@/services/http'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class firstLog extends Vue {
  loader = false
  password = ''
  confirm_password = ''
  userData: any | null = null
  mounted() {
    this.userData = authModule.user
  }
  async next() {
    this.loader = true
    try {
      const id = authModule.ID
      await http.post(`api/account/password_change/${id}/`, {
        password: this.password,
        confirm_password: this.confirm_password,
      })
      this.$router.push({ path: '/dashboard' }).catch()
    } catch (error: any) {
      this.$buefy.toast.open({
        message: error.response.data.detail,
        type: 'is-danger',
      })
    }
    this.loader = false
  }
}
