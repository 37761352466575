var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loader)?_c('loader'):_c('div',{staticClass:"container is-fluid pt-6"},[_c('div',{staticClass:"columns pt-6"},[_c('div',{staticClass:"column pt-6"},[_c('div',{staticClass:"is-flex is-justify-content-center pt-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"title is-4"},[_vm._v(_vm._s(_vm.$t('Welcome'))+"!")]),_c('div',{staticClass:"content"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('ValidationProvider',{attrs:{"rules":"required|password_validate","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text has-text-left",attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label":_vm.$t('Enter your new password')}},[_c('b-input',{attrs:{"type":"password","password-reveal":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('br'),_c('ValidationProvider',{attrs:{"rules":"required|password:@password","name":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text has-text-left",attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label":_vm.$t('Re-enter your new password')}},[_c('b-input',{attrs:{"type":"password","password-reveal":""},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}})],1)]}}],null,true)}),_c('br'),_c('br'),_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"is-primary","native-type":"submit","expanded":""},on:{"click":function($event){return handleSubmit(_vm.next)}}},[_vm._v(_vm._s(_vm.$t('Confirm')))])],1)]}}])})],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }